import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Navbar from "../navbar/Navbar";
import Logo from "../logo/Logo";
import "./About.css";
import "../logo/Logo.css";

function About() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="about-container">
      <header>
        <Navbar />
      </header>
      <main>
        {isMobile && (
          <div className="logo-container-mobile">
            <Logo width={"20%"} />
          </div>
        )}
        <Container>
          <Row>
            <Col md={6}>
              <div className={isMobile ? "about-img-container-mobile" : "about-img-container"}>
                <Image
                  src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5257.jpg"
                  alt="Profile Picture"
                  fluid
                />
              </div>
            </Col>
            <Col md={6}>
              {!isMobile && (
                <div className="logo-container my-5">
                  <Logo width={"20%"} />
                </div>
              )}
              <div className="about-text my-4">
                <p>
                  A Gilmore Young Artist and Salon de Virtuosi recipient, Indonesian 
                  pianist Janice Carissa is celebrated for her ability to craft 
                  vivid musical narratives that transcend mere virtuosity 
                  (Chicago Classical Review). She has performed for the President 
                  of Indonesia at the Presidential Palace Indonesia, and earned 
                  ovations in the United Nations, Carnegie Hall, Sydney Opera House 
                  and Louis Vuitton Foundation, among others. 
                </p>
                <p>
                  Following her Philadelphia Orchestra debut at sixteen, which earned 
                  praise from the Philadelphia Inquirer for “radiating the multicolored 
                  highlights of a mature pianist,” she has collaborated with luminaries 
                  including Stéphane Denève, Cristian Măcelaru, Peter Oundjian, Osmo 
                  Vänskä, and Jahja Ling; stepped in for Andre Watts with the Orpheus 
                  Chamber Orchestra; and featured as a soloist with the Kansas City, 
                  Nashville, Curtis, Promusica, Amarillo, Sacramento, Kalamazoo, Bay 
                  Atlantic, Battlecreek, Symphony in C, and Tacoma symphonies. 
                </p>
                <p>
                  Engagements for the 2024-2025 season will include debuts with the 
                  Pittsburgh, Knoxville, Delaware, Erie, Boise, Lakeside symphonies, 
                  a residency at Durango’s Music in the Mountains, and solo recitals 
                  across the United States showcasing repertoire spanning from Bach 
                  to Rzewski, and a world premiere of Eunike Tanzil’s Five Miniatures. 
                </p>
                <p>
                  Recent career highlights include an invitation from the San Diego 
                  Symphony to perform Ravel's Left Hand Concerto, which was described 
                  by the San Diego Tribune as "masterful.” Equally compelling was her 
                  interpretation of Mozart's Piano Concerto No. 17 in G, K. 453 with 
                  the Nashville Symphony, which Music City Review hailed as "a 
                  masterful performance that earned the ovation." 
                </p>
                <p>
                  Equally at home in intimate settings, Carissa is a sought-after 
                  chamber musician and has collaborated with Vadim Gluzman, Miriam 
                  Fried, Pamela Frank, David Shifrin, Marcy Rosen, Paul Neubauer, 
                  Shmuel Ashkenazy, Zlatomir Fung, Lucy Shelton and members of the 
                  Berlin Philharmonic’s Scharoun Ensemble. Her versatility and 
                  deep commitment to collaboration has been showcased at the 
                  Marlboro, Ravinia, Bravo! Vail, Caramoor, North Shore, and 
                  Kneisel Hall festivals. Carissa is a member of an American 
                  collective of soloists and chamber musicians called Ensemble 132, 
                  and appears regularly with the Jupiter Chamber Players.
                </p>
                <p>
                  A committed advocate for contemporary compositions, Carissa has 
                  premiered works by Carl Vine—made possible through the Gilmore’s 
                  generous support—Timo Andres, Wang Jie, Katherine Balch, Alistair 
                  Coleman, and Alyssa Weinberg.
                </p>
                <p>
                  Born in Surabaya, Indonesia, Carissa began her piano studies under 
                  her mother's guidance. Now settled in New York, she moved to the 
                  United States at fifteen to study at the Curtis Institute of Music 
                  with Gary Graffman and Robert McDonald, later completing her 
                  Master's at The Juilliard School as a Kovner Fellow. Her journey 
                  has been enriched by support from the Lang Lang and Lieven 
                  Foundations, as well as mentorship from Eliso Virsaladze, Ingrid 
                  Fliter, Alon Goldstein, Davide Cabassi, Claudio Martinez-Mehner, 
                  Andreas Staier, and Tatiana Zelikmann. When away from the stage, 
                  Carissa enjoys immersing herself in the world of food and photography. 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      {/* <footer>
        <Footer />
      </footer> */}
    </div>
  );
}

export default About;
